import { render, staticRenderFns } from "./PageHeader-4.2.vue?vue&type=template&id=6eeddac5&scoped=true&"
import script from "./PageHeader-4.2.vue?vue&type=script&lang=js&"
export * from "./PageHeader-4.2.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader-4.2.vue?vue&type=style&index=0&id=6eeddac5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eeddac5",
  null
  
)

export default component.exports